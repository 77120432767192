var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"mx-auto max-w-ctr-wrapper min-h-[50px]"},[_c('ClientOnly',[_c('div',{staticClass:"flex items-center text-black font-bold relative menu-bar -mx-2.5 xl:-mx-4",class:("swiper-" + _vm.uid)},[_c('div',{staticClass:"relative flex-shrink-0 left-2.5 xl:left-4"},[_c('button',{staticClass:"h-full relative z-[3] flex items-center justify-start px-2.5 w-12 swiper-btn-prev text-ctr-secondary",attrs:{"type":"button"}},[_c('BaseIcon',{attrs:{"name":"ctr-arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"absolute inset-y-0 left-0 z-0 w-20 bg-gradient-to-r from-white/50"})]),_vm._v(" "),_c('Swiper',{staticClass:"global-nav flex-1",attrs:{"options":_vm.swiperOptions}},[(_vm.mainMenu && _vm.mainMenu.length)?_vm._l((_vm.mainMenu),function(item){return _c('SwiperSlide',{key:("mainMenu-" + (item.key)),staticClass:"!w-auto"},[_c('AppHeaderNavDropdown',{staticClass:"global-nav__item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shown = ref.shown;
return [_c(item.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"global-nav__link font-bold flex items-center h-14 px-2.5 xl:px-4 hover:bg-ctr-grey-300",class:{
                      'bg-ctr-grey-300': shown,
                    }},'component',
                      item.alternateUrl
                        ? {
                            href: item.alternateUrl,
                          }
                        : {
                            to: _vm.localePath(
                              item.url
                                ? {
                                    name: 'all',
                                    params: { pathMatch: item.url },
                                  }
                                : {
                                    name: 'index',
                                  }
                            ),
                          }
                    ,false),[_c('span',{staticClass:"block truncate"},[_vm._v(_vm._s(item.name))]),_vm._v(" "),(item.children && item.children.length)?_c('BaseIcon',{staticClass:"w-3 h-3 ml-1.5",attrs:{"name":"ctr-arrow-down"}}):_vm._e()],1),_vm._v(" "),(item.children && item.children.length)?[_c('BaseBackdropOverlay',{attrs:{"visible":shown,"z-index":"z-40","append-to-body":true}})]:_vm._e()]}},(item.children && item.children.length)?{key:"popper",fn:function(){return [_c('ul',{staticClass:"dropdown bg-white drop-shadow border border-ctr-grey-400 min-w-48 max-w-60"},_vm._l((item.children),function(subItem){return _c('li',{key:("sub-" + (subItem.key)),staticClass:"dropdown__item group relative hover:bg-ctr-grey-300"},[_c(subItem.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"dropdown__link font-bold flex items-center h-10 w-full px-2.5 xl:px-4",attrs:{"title":subItem.name}},'component',
                          subItem.alternateUrl
                            ? {
                                href: subItem.alternateUrl,
                              }
                            : {
                                to: _vm.localePath(
                                  subItem.url
                                    ? {
                                        name: 'all',
                                        params: { pathMatch: subItem.url },
                                      }
                                    : {
                                        name: 'index',
                                      }
                                ),
                              }
                        ,false),[_c('span',{staticClass:"block truncate pr-2.5"},[_vm._v("\n                          "+_vm._s(subItem.name)+"\n                        ")]),_vm._v(" "),(subItem.children && subItem.children.length)?_c('BaseIcon',{staticClass:"w-3 h-3 ml-auto",attrs:{"name":"ctr-arrow-right"}}):_vm._e()],1),_vm._v(" "),(subItem.children && subItem.children.length)?_c('ul',{staticClass:"hidden group-hover:block absolute left-full top-0 -mt-px z-10 bg-white border border-ctr-grey-400 min-w-48 max-w-60"},_vm._l((subItem.children),function(subSubItem){return _c('li',{key:("sub-sub-" + (subSubItem.key)),staticClass:"hover:bg-ctr-grey-300"},[_c(subSubItem.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"font-bold w-full flex items-center h-10 px-2.5 xl:px-4",attrs:{"title":subSubItem.name}},'component',
                              subSubItem.alternateUrl
                                ? {
                                    href: subSubItem.alternateUrl,
                                  }
                                : {
                                    to: _vm.localePath(
                                      subSubItem.url
                                        ? {
                                            name: 'all',
                                            params: {
                                              pathMatch: subSubItem.url,
                                            },
                                          }
                                        : {
                                            name: 'index',
                                          }
                                    ),
                                  }
                            ,false),[_c('span',{staticClass:"block truncate"},[_vm._v("\n                              "+_vm._s(subSubItem.name)+"\n                            ")])])],1)}),0):_vm._e()],1)}),0)]},proxy:true}:null],null,true)})],1)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"relative flex-shrink-0 right-2.5 xl:right-4"},[_c('button',{staticClass:"h-full relative z-[3] flex items-center justify-end px-2.5 w-12 swiper-btn-next text-ctr-secondary",attrs:{"type":"button"}},[_c('BaseIcon',{attrs:{"name":"ctr-arrow-right"}})],1),_vm._v(" "),_c('div',{staticClass:"absolute inset-y-0 right-0 z-0 w-20 bg-gradient-to-l from-white/40"})])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }