import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=bc3af360&scoped=true&"
import script from "./MenuBar.vue?vue&type=script&lang=ts&"
export * from "./MenuBar.vue?vue&type=script&lang=ts&"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=bc3af360&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc3af360",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/112/a/NuxtApp/components/base/Icon.vue').default,BaseBackdropOverlay: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/112/a/NuxtApp/components/base/BackdropOverlay.vue').default,AppHeaderNavDropdown: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/112/a/NuxtApp/components/app/header/NavDropdown.vue').default})
